import React, { useEffect } from 'react';

import SearchField from '../../../components/search-field/search-field.component';

import './protected-header.styles.scss';

import IconUser from '../../../assets/icons/user.svg';

import IconHamburger from '../../../assets/icons/hamburger.svg';

import { createStructuredSelector } from 'reselect';
import { selectBurgerHidden, selectSupplierDropdownHidden, selectWindowWidth } from '../../../redux/navigation/navigation.reselect';
import { connect } from 'react-redux';
import NavDropdown from '../../nav-dropdown/nav-dropdown.components';
import { setBurgerHidden, setSupplierDropdownHidden } from '../../../redux/navigation/navigation.actions';
import { NAV_ITEMS } from './nav.data';
import { SETTING_NOTFICATION_BAR } from './nav.data';
import { setShowAddSupplierGroupModal, setShowAddSupplierModal } from '../../../redux/modal/modal.actions';
import { Link, withRouter } from 'react-router-dom';
import Logo from '../../logo/logo.componenet';
import NavItem from '../../nav-item/nav-item.component';
import { useRef } from 'react';
import SettingNotificationBar from '../../setting-notification-bar/setting-notification-bar.component';
import AccountDisplay from '../../account-display/account-disaplay.componenet';

const ProtectedHeader = ({
    supplierDropdownHidden,
    setSupplierDropdownHidden,
    burgerHidden,
    setBurgerHidden,
    windowWidth,
    setShowAddShupplierModal,
    setShowAddSupplierGroupModal,
    history
}) => {
    const navRef = useRef(null);

    const handleBurgerClick = (event) => {
        setBurgerHidden();
    }

    useEffect(() => {
        const nav = navRef.current
        windowWidth > 991 ?
            nav.style.transform = 'translate(0px)' :
            !burgerHidden ?
                nav.style.transform = 'translate(0px)' :
                nav.style.display = nav.style.transform = 'translate(-1000px)'
    });

    useEffect(() => {
        if (window.innerWidth < 991 && !burgerHidden) {
            document.body.style.overflow = "hidden"
        } else if (window.innerWidth < 991 && burgerHidden) {
            document.body.style.overflow = "scroll"
        }
    }, [burgerHidden])

    return (
        <nav className="protected-header container-fluid">
            <div className="protected-header__container container-fluid">
                <div className="protected-header__container__top">
                    <div className="protected-header__toggle">
                        <button className='protected-header__toggle__button' id="burger" onClick={handleBurgerClick}>
                            <img src={IconHamburger} className="protected-header__toggle__button__icon" alt="burger" />
                        </button>
                    </div>
                    <div className="protected-header__logo">
                        <Link to={'/home'}>
                            <Logo width="110"/>
                        </Link>
                    </div>
                    <div className="protected-header__nav" ref={navRef}>
                        <ul className="protected-header__nav__items">
                            {
                                NAV_ITEMS.map((item, index) => {
                                    return (
                                        <NavItem
                                            key={index}
                                            navItem={item}
                                        />
                                    )
                                })
                            }
                        </ul>

                    </div>
                    <div className="protected-header__setting-notification-account">
                        <ul className="protected-header__setting-notification-account__items">
                            {
                                SETTING_NOTFICATION_BAR.map((item, index) => {
                                    return (
                                        <SettingNotificationBar
                                            key={index}
                                            navItem={item}
                                        >
                                            <AccountDisplay />
                                        </SettingNotificationBar>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

const mapStateToProps = createStructuredSelector({
    supplierDropdownHidden: selectSupplierDropdownHidden,
    burgerHidden: selectBurgerHidden,
    windowWidth: selectWindowWidth,
});

const mapDispatchToProps = (dispatch) => ({
    setBurgerHidden: () => dispatch(setBurgerHidden()),
    setSupplierDropdownHidden: () => dispatch(setSupplierDropdownHidden()),
    setShowAddShupplierModal: () => dispatch(setShowAddSupplierModal()),
    setShowAddSupplierGroupModal: () => dispatch(setShowAddSupplierGroupModal())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedHeader));