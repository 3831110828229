import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
// import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
// import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
// import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
// import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
// import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const EXPIRATION_REPORT_BY_ORDER_INFO = {
  reportTitle: "Inventory Reports",
  path: "/inventoryreports",
  subPath: "/productexpiration",
  listPage: "/product-expiration-reports",
  hasChart: false,
  tableHasGrandTotal: true,
}

export const EXPIRATION_REPORT_BY_ORDER_COLUMN_TEMPLATE = [
  {
    Header: "Batch Number",
    accessor: "itemCode",
    Footer: 'Grand Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
  },
  {
    Header: "Qty on Hand",
    accessor: "totalQtyOnHand",
    Cell: ({ value }) => {
        return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "Expiration Date",
    accessor: "expirationDate",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Status",
    accessor: "expirationStatus",
    Cell: ({ value }) => {
      if (!value) {
        return displayCellValue(value);
      }

      return (
        <TableCellStatus
          value={value}
        />
      )
    }
  },
]
