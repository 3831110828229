import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/salesorders";

export const SALES_ORDERS_COLUMNS = [
    {
        Header: "SO Number",
        accessor: "salesOrderNumber",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "Customer",
        accessor: "customerName",
    
        Cell: ({ value }) => {
            return displayCellValue(value);
        },
    },
    {
        Header: "Status",
        accessor: "salesOrderStatus",
        Cell: ({ value }) => {
            if (!value) {
                return displayCellValue(value);
            }
            return (
                <TableCellStatus
                    value={value}
                />
            )
        }
    },
    {
        Header: "Order Date",
        accessor: "salesOrderDate",
        Cell: ({ value }) => (
            format(new Date(value), 'dd/MM/yyyy')
        )
    },
    {
        Header: "Location",
        accessor: "locationCode",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Grand Total (ETB)",
        accessor: "grandTotal",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Approval",
        accessor: "approvalStatus",
        Cell: ({ value }) => {
            if (!value) {
                return displayCellValue(value);
            }
            return (
                <TableCellStatus
                    value={value}
                />
            )
        }
    },
]