import React from 'react';

const withFormTemplate = WrappedComponent => {
    const form = ({ formType, 
        formTemplate, 
        isLoading = false, 
        endpoint, 
        endpointForQuickComplete, 
        isViewOnly, 
        isNoDeactivate,
        showReport,
        reportColumn,
        reportEndpoint,
        dataKeys,
        hasNoDate
    }) => {
        return formTemplate ?
            <WrappedComponent
                isLoading={isLoading}
                endpoint={endpoint}
                endpointForQuickComplete={endpointForQuickComplete}
                isViewOnly={isViewOnly}
                isNoDeactivate={isNoDeactivate}
                showReport={showReport}
                reportColumn={reportColumn}
                reportEndpoint={reportEndpoint}
                dataKeys={dataKeys}
                hasNoDate={hasNoDate}
            /> :
            null
    }

    return form;
};

export default withFormTemplate;