import { useState } from 'react';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    useTable,
    useResizeColumns,
    useBlockLayout,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
    useFlexLayout
} from 'react-table';

import "./report-table.styles.scss";


import IconArrowUpward from '../../assets/icons/arrow_upward.svg';
import IconArrowDownward from '../../assets/icons/arrow_downward.svg';

import useFetchReport from '../../effects/use-fetch-report.effect';

import { connect } from 'react-redux';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { selectFilterInput, selectLocalFilterFieldNamesAndValues } from '../../redux/filter/filter.reselect';
import { selectIsRefresh } from '../../redux/refresh/refresh.reselect';
import { selectDeactivateCheck, selectScrollPosition } from '../../redux/list/list.reselect';
import { setExportData } from '../../redux/export/export.actions';
import { setRefresh } from '../../redux/refresh/refresh.actions';
import { setDeactivateCheck, setScrollPosition } from '../../redux/list/list.actions';
import { createStructuredSelector } from 'reselect';
import Spinner from '../spinner/spinner.component';
import ErrorBox from '../error-box/error-box.component';
import { useEffect } from 'react';
import { selectIsReportDirty, selectReportDateRange, selectReportInfo, selectReportSideBarNav, selectReportSideBarNavActiveItem } from '../../redux/report/report.reselect';
import { format } from 'date-fns';
import { useRef } from 'react';
import Paragraph from '../paragraph/paragraph.componet';
import { setReportSummaryData, setReportDateRange } from '../../redux/report/report.actions';
import { formatNumberToTwoDecimal, stringToNumber } from '../../utils/format-number.util';
import { getThisWeek } from '../../utils/date.utils';



const ReportTable = ({
    reportColumn,
    endpoint,
    authToken,
    filterInput,
    reportDateRange,
    setExportData,
    setRefresh,
    isRefresh,
    deactivateCheck,
    setDeactivateCheck,
    scrollPosition,
    setReportDateRange,
    setReportSummaryData,
    reportSideBarNavActiveItem,
    reportInfo,
    hasNoDate
}) => {
    const thisWeek = getThisWeek();
    const [defaultDateRange, setDefaultDateRange] = useState({
        startDate: format(thisWeek.startDate, "yyyy-MM-dd"),
        endDate: format(thisWeek.endDate, "yyyy-MM-dd"),
    })

    const limit = 10;
    const [skip, setSkip] = useState(0);
    
    const [hasMore, setHasMore] = useState(true);
    const { data, dataExport, summaryData, totalData, numItems, error, isLoading } = useFetchReport(
        endpoint,
        authToken,
        setSkip,
        skip,
        limit,
        !reportDateRange || !Object.keys(reportDateRange).length ? defaultDateRange : reportDateRange,
        isRefresh,
        reportSideBarNavActiveItem,
        deactivateCheck,
        hasNoDate
    );

    console.log(data)

    const columns = useMemo(() => {
        return reportColumn.map((column) => {
            for (const key of Object.keys(totalData)) {
                if (key === column.accessor) {
                    column.Footer = formatNumberToTwoDecimal(stringToNumber(totalData[key]));
                }
            }

            return column
        })

    }, [totalData, reportSideBarNavActiveItem]);


    const fetchMoreData = async () => {
        if (data.length >= numItems) {
            setHasMore(false)
        }
        setSkip(prevSkip => (prevSkip + limit));
    }

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 140,
            maxWidth: 400
        }),
        []
    );

    const tableMethods = useTable({
        columns,
        data,
        defaultColumn
    },
        useGlobalFilter,
        useResizeColumns,
        useSortBy,
        useRowSelect,
        useFlexLayout
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        state,
        selectedFlatRows,
        state: {
            globalFilter
        },
        setGlobalFilter,
    } = tableMethods;


    useEffect(() => {
        if (numItems <= limit) {
            setHasMore(false)
        } else if (data.length >= numItems) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
    })

    useEffect(() => {
        setExportData(dataExport);
    }, [dataExport])

    useEffect(() => {
        if (isRefresh) {
            setHasMore(true);
            setRefresh(false);
        }
    }, [isRefresh])

    useEffect(() => {
        setReportSummaryData([])
    }, [reportSideBarNavActiveItem])

    useEffect(() => {
        if (summaryData.length) {
            setReportSummaryData(summaryData)
        }else{
            setReportSummaryData({})
        }
    }, [summaryData])

    const scrollableDiv = useRef();
    const scrollPositionRef = useRef(0);

    const handleOnScroll = () => {
        scrollPositionRef.current = scrollableDiv.current.scrollTop
    }

    useEffect(() => {
        if (scrollPosition && skip !== 0) {
            scrollableDiv.current.scrollTop = scrollPosition;
        }
    }, [])

    useEffect(() => {
        return () => {
            setScrollPosition(scrollPositionRef.current)
            setReportDateRange({})
            setReportSummaryData([])
        }
    }, [])

    return (
        <div className="report-table-container">
            {
                isLoading ?
                    <div className='report-table-container__spinner'>
                        <Spinner />
                    </div> :
                    error ?
                        <div className='report-table-container__error'>
                            <ErrorBox
                                error={error}
                                icon={error.status ?
                                    error.status === 404 ? false :
                                        true :
                                    true
                                }
                            />
                        </div> :
                        <>
                            {reportInfo && reportInfo.hasDateRange &&
                                <div className="report-table-container__date-range">
                                    {`From ${format(new Date(reportDateRange.startDate), "dd/MM/yyyy")} 
                                To ${format(new Date(reportDateRange.endDate), "dd/MM/yyyy")}`}
                                </div>
                            }


                            <div className="scrollable-report" id="scrollableDiv"
                                ref={scrollableDiv} onScroll={handleOnScroll}
                            >
                                <table className="report-table" {...getTableProps()}>
                                    <thead className="report-table__header">
                                        {headerGroups.map(headerGroup => (
                                            <tr className="report-table__header__group" {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        className="report-table__header__item"
                                                        {...column.getHeaderProps([

                                                            {
                                                                className: column.className,
                                                                style: column.style
                                                            }
                                                        ])}
                                                    >
                                                        <span {...column.getSortByToggleProps()}>
                                                            {column.render('Header')}
                                                        </span>
                                                        <span className="report-table__header__item__sort">
                                                            {column.isSorted ?
                                                                column.isSortedDesc ?
                                                                    <img
                                                                        src={IconArrowDownward}
                                                                        className="report-table__header__item__sort__icon"
                                                                    /> :
                                                                    <img
                                                                        src={IconArrowUpward}
                                                                        className="report-table__header__item__sort__icon"
                                                                    />
                                                                : ''}
                                                        </span>
                                                        <span style={{ border: '3px solid coral', width: '3px', height: '40px' }}
                                                            {...column.getResizerProps()}
                                                            className={`report-table__header__item__resizer 
                                            ${column.isResizing ?
                                                                    "report-table__header__item__resizer--isResizing" : ""
                                                                }`}
                                                        />
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <InfiniteScroll
                                        className="infinite-scroll"
                                        dataLength={data.length}
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={
                                            <div className="infinite-scroll__spinner">
                                                <Spinner position="center" size="large" />
                                            </div>
                                        }
                                        scrollableTarget="scrollableDiv"
                                        endMessage={
                                            <Paragraph>
                                                No more data, you have reached the end.
                                            </Paragraph>
                                        }

                                    >
                                        <tbody className="report-table__body" {...getTableBodyProps()}>
                                            {rows.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <tr className="report-table__body__row" {...row.getRowProps()} >
                                                        {row.cells.map(cell => (
                                                            <td
                                                                className="report-table__body__row__cell"
                                                                {...cell.getCellProps([
                                                                    {
                                                                        className: cell.column.className,
                                                                        style: cell.column.style
                                                                    }
                                                                ])}
                                                                data-label={cell.render('Header')}
                                                            >
                                                                <span className="report-table__body__row__cell__header">
                                                                    {cell.render('Header')}
                                                                </span>
                                                                <span className="report-table__body__row__cell__data">
                                                                    {cell.render('Cell')}
                                                                </span>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </InfiniteScroll>
                                    {reportInfo && reportInfo.tableHasGrandTotal &&
                                        <tfoot className="report-table__footer">
                                            {footerGroups.map(group => (
                                                <td className="report-table__footer__group" {...group.getFooterGroupProps()}>
                                                    {group.headers.map(column => (
                                                        <td className="report-table__footer__item" {...column.getFooterProps()}>
                                                            {column.render('Footer')}
                                                        </td>
                                                    ))}
                                                </td>
                                            ))}
                                        </tfoot>
                                    }
                                </table>
                            </div>
                        </>
            }
        </div >

    )
}

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    filterInput: selectFilterInput,
    localFilterFieldNamesAndValues: selectLocalFilterFieldNamesAndValues,
    isRefresh: selectIsRefresh,
    deactivateCheck: selectDeactivateCheck,
    reportDateRange: selectReportDateRange,
    scrollPosition: selectScrollPosition,
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem,
    reportInfo: selectReportInfo
});

const mapDispatchToProps = (dispatch) => ({
    setExportData: (exportData) =>
        dispatch(setExportData(exportData)),
    setRefresh: (data) =>
        dispatch(setRefresh(data)),
    setDeactivateCheck: (data) =>
        dispatch(setDeactivateCheck(data)),
    setScrollPosition: (position) =>
        dispatch(setScrollPosition(position)),
    setReportDateRange: (data) =>
        dispatch(setReportDateRange(data)),
    setReportSummaryData: (data) =>
        dispatch(setReportSummaryData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable)
