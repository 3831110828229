import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
// import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
// import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
// import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
// import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
// import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const PRODUCTION_REPORT_BY_ORDER_INFO = {
  reportTitle: "Inventory Reports",
  path: "/inventoryreports",
  subPath: "/workorderreport",
  listPage: "/work-order-reports",
  hasChart: false,
  tableHasGrandTotal: true,
}

export const PRODUCTION_REPORT_BY_ORDER_COLUMN_TEMPLATE = [
  {
    Header: "WO Number",
    accessor: "workOrderNumber",
    Footer: 'Grand Total'
  },
  {
    Header: "Work Order Status",
    accessor: "workOrderStatus",
    Cell: ({ value }) => {
      if (!value) {
        return displayCellValue(value);
      }

      return (
        <TableCellStatus
          value={value}
        />
      )
    }
  },
  {
    Header: "Location",
    accessor: "locationCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Order Date",
    accessor: "workOrderDate",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },

  {
    Header: "Total Cost (ETB)",
    accessor: "totalCost",
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'totalCost'
  },
]
