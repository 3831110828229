import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
// import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
// import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
// import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
// import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
// import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const ITEM_MOVEMENT_HISTORY_INFO = {
  formTitle: "Product",
  path: "/items",
  subPath: "/movementhistory",
  listPage: "/items",
  report:true
}

export const ITEM_MOVEMENT_HISTORY_COLUMN_TEMPLATE = [
  {
    Header: "Type",
    accessor: "type",
    Footer: 'Grand Total'
  },
  // {
  //   Header: "Inventory Status",
  //   accessor: "inventoryStatus",
  //   Cell: ({ value }) => {
  //     if (!value) {
  //       return displayCellValue(value);
  //     }

  //     return (
  //       <TableCellStatus
  //         value={value}
  //       />
  //     )
  //   }
  // },
  {
    Header: "Code",
    accessor: "code",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Movement Date",
    accessor: "date",
    Cell: ({ value }) => {
      
      return format(new Date(value), 'dd/MM/yyyy');
    }
  },
  {
    Header: "Location",
    accessor: "location",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  // {
  //   Header: "Qty Changes",
  //   accessor: "qty",
  //   Cell: ({ value }) => {
  //     return displayCellValueNumberToTwoDecimal(value);
  //   },
  //   Footer: 'grandTotalSales'
  // }

   {
    Header: "Qty Changes",
    accessor: "qty",
    Cell: ({ value }) => {
      if (!value) {
        return displayCellValue(value);
      }
    
      return (
        <TableCellStatus
          value={value}
          noDot={true}
        />
      )
    }
  },

]
