import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const STOCK_TRANSFER_INFO = {
  formTitle: "Stock Transfer",
  formTitleForNew: "New Stock Transfer",
  path: "/stocktransfers",
  listPage: "/stock-transfers",
}

export const STOCK_TRANSFER_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 3,
      fieldName: "stockTransfer",
      fieldItems: [
        {
          label: "Transfer Code",
          type: "text",
          name: "transferCode",
          initialFocus: false,
          disabled: true,
          defaultValue: `ST-`
        },
        {
          label: "From Location",
          type: "select",
          name: "fromLocation",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
          validationProps: {
            required: "Location is required"
          }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   muliselect: false,
        //   url: "/users"
        // },
        {
          label: "Approved By",
          type: "text",
          name: "approvedBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Request Date",
          type: "date",
          name: "requestedDate",
          initialFocus: false,
          validationProps: {
            required: "Request Date date is required"
          }
        },
        {
          label: "To Location",
          type: "select",
          name: "toLocation",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
          validationProps: {
            required: "Location is required"
          }
        }
      ]
    },
    approvalDetails: {
      sectionType: "Approval details",
      sectionTypeId: "approvalDetails",
      multiForm: false,
      expandable: true,
      isExpand: true,
      showEditOnly: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "approvalStatus",
      fieldItems: [
        {
          label: "Current Approval Status",
          type: "select",
          name: "ApprovalStatus",
          initialFocus: false,
          muliselect: false,
          defaultValue: { label: 'Unapproved', value: 2 },
          url: "/approvalstatus"
        }
      ]
    },
    tableForm: {
      sectionType: "Transfer Items Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: false,
      tableColumns: [
        {
          Header: "No.",
          id: "count",
          Cell: ({ row }) => Number(row.id) + 1,
          width: 30,
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Transfer Qty"}
                required={false}
              />
            )
          },
          accessor: "transferQty",
          width: 160,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"transferQty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History details",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true,
      addButton: true,
      position: "LAST",
      breakPoint: 1,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: []
}
