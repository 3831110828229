import React from "react";

import TitleHeader from "../title-header/title-header.componenet";
import CustomButton from "../custom-button/custom-button.componenet";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { selectFormEdit, selectFormTemplate, selectIsComplete, selectIsLoading } from "../../redux/form/form.reselect";
import ButtonSpinner from "../button-spinner/button-spinner.compoenent";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import "./form-header.styles.scss";

import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import DetailRefresh from "../detail-refresh/detail-refresh.component";
import DetailPrintView from "../detail-print-view/detail-print-view.component";
import DocumentViewButton from "../document-view-button/document-view-button.component";
import { selectDocumentDropDownItems } from "../../redux/document/document.reselect";
import Deactivate from "../deactivate/deactivate.componenet";
import { setFormForComplete } from "../../redux/form/form.actions";
import { authTokenSelector } from "../../redux/user/user.reselect";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { selectModalFormTemplate } from "../../redux/modal/modal.reselect";
import { selectEditUUID } from "../../redux/report/report.reselect";

const FormHeader = ({
    authToken,
    handleSideNav,
    formTitle,
    editUUID,
    isLoading,
    handleCloseForm,
    handleSave,
    handleComplete,
    formEdit,
    documentDropDownItems,
    isViewOnly,
    isNoDeactivate,
    endpoint,
    setFormForComplete,
    quickComplete,
    isComplete
}) => {
    const formHeaderRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const url = `${endpoint}/completestatus`;

    useEffect(() => {
        if(quickComplete){
            setFormForComplete(authToken, url);
        }
    }, [isLoading, quickComplete])

    const setStickHeader = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 95) {
            return setIsSticky(true);
        }

        setIsSticky(false)
    }

    const setWindowSize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", setStickHeader);
        window.addEventListener("resize", setWindowSize);

        return () => {
            window.removeEventListener("scroll", setStickHeader);
            window.removeEventListener("scroll", setWindowSize)
        }
    }, []);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 991) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [])

    return (
        <div className={`form-header  ${isSticky ? "form-header--sticky" : null}`} ref={formHeaderRef}>
            <TitleHeader
                hamburger={true}
                handleSideNav={handleSideNav}
            >
                {formTitle}
            </TitleHeader>
            <div className="form-header__tools">
                <div className='form-header__spinner'>
                    {
                        isLoading &&
                        <ButtonSpinner />
                    }
                </div>
                <div className="form-header__tools__actions">
                    {
                        formEdit &&
                        <>
                            {!isMobile && documentDropDownItems.length ? <DocumentViewButton
                                DropDownButton={DropDownButton}
                            /> : null}
                            {!isViewOnly && !isNoDeactivate && <Deactivate
                                DropDownButton={DropDownButton}
                            />}
                            <DetailRefresh
                                DropDownButton={DropDownButton}
                            />
                        </>
                    }
                    {!isMobile &&
                        <CustomButton
                            inverted={true}
                            handleClick={(event) => { handleCloseForm(event) }}
                        >
                            Cancel
                        </CustomButton>
                    }
                    {!isViewOnly && quickComplete && formEdit &&
                        <CustomButton
                            isLoading={isLoading}
                            isComplete={isComplete}
                            handleClick={(event) => { handleComplete(event) }}
                        >
                            {isComplete? "Completed" : "Complete"}
                        </CustomButton>
                    }
                    {!isViewOnly &&
                        <CustomButton
                            isLoading={isLoading}
                            handleClick={(event) => { handleSave(event) }}
                        >
                            Save 
                        </CustomButton>
                    }

                </div>
            </div>
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    isLoading: selectIsLoading,
    formEdit: selectFormEdit,
    editUUID: selectEditUUID,
    documentDropDownItems: selectDocumentDropDownItems,
    isComplete: selectIsComplete,
  
});

const mapDispatchToProps = (dispatch) => ({
    setFormForComplete: (authToken, url) =>
        dispatch(setFormForComplete(authToken, url))
})

export default connect(mapStateToProps, mapDispatchToProps)(FormHeader);
