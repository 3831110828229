import React, { useEffect, useRef } from 'react';
import clone from 'clone';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { SALES_ORDER_INFO, SALES_ORDER_FORM_TEMPLATE } from './sales-order.template';
import { PICK_INFO, PICK_FORM_TEMPLATE } from '../../pick/pick-form/pick.template';
import { PACK_INFO, PACK_FORM_TEMPLATE } from '../../pack/pack-form/pack.template';
import { SHIP_INFO, SHIP_FORM_TEMPLATE } from '../../ship/ship-form/ship.template';
import { RESTOCK_INFO, RESTOCK_FORM_TEMPLATE } from '../../restock/restock-form/restock.template';
import { SALES_RETURN_INFO, SALES_RETURN_FORM_TEMPLATE } from '../../sales-return/sales-return-form/sales-return.template';
import { SALES_ORDER_DOCUMENT_DROP_DOWN_ITEMS } from './sales-order-documents-nav.template';

import FormContainer from '../../../components/form/form.container';
import { selectFormSideBarNavActiveItem, selectFormTableLatestData, selectFormTableLatestRows, selectSelectedField } from '../../../redux/form/form.reselect';
import { resetErrorMessageAndIsFail, setEditDataForm, setFormChangedValues, setFormEdit, setFormInfo, setFormProgress, setFormSideBarNav, setFormSideBarNavActiveItem, setFormTableData, setFormTableLatestData, setFormTableLatestRows, setFormTemplate, setFormTemplateEnableField, setFormTotalValues, setFormType, setFromValidationError, setSelectedFieldValue, setValueEntredBlur } from '../../../redux/form/form.actions';
import { useState } from 'react';
import { removeEmptyValuedObjects } from '../../../utils/object-values.util';
import { removeObjectWithoutSpecifiedPropertiesHasValue } from '../../../utils/array-values.utils';
import { formatNumberToTwoDecimal, roundNumberTwoDecimal, stringToNumber } from '../../../utils/format-number.util';
import { Link, withRouter } from 'react-router-dom';
import useFetchEdit from '../../../effects/use-fetch-edit.effect';
import { authTokenSelector, selectAccessRights } from '../../../redux/user/user.reselect';
import { selectIsRefresh } from '../../../redux/refresh/refresh.reselect';
import { setDocumentDropDownItems } from '../../../redux/document/document.actions';
import useFetchFieldValues from '../../../effects/use-fetch-field-values';
import { disableFormFields } from '../../../utils/form-values.utils';

import { MdShoppingCart } from "react-icons/md";
import { MdForklift } from "react-icons/md";
import { FaPeopleCarryBox } from "react-icons/fa6";
import { BsBoxSeamFill } from "react-icons/bs";
import { FaTruckRampBox } from "react-icons/fa6";
import { GiReturnArrow } from "react-icons/gi";
import { TbTruckReturn } from "react-icons/tb";

const APP_NAME = `sales_order`;

const SUB_ITEMS = [
    {
        text: "Sales Order",
        icon: MdShoppingCart,
        info: SALES_ORDER_INFO,
        formTemplate: SALES_ORDER_FORM_TEMPLATE
    },
    {
        text: "Pick",
        icon: FaPeopleCarryBox,
        info: PICK_INFO,
        formTemplate: PICK_FORM_TEMPLATE
    },
    // {
    //     text: "Pack",
    //     icon: BsBoxSeamFill,
    //     info: PACK_INFO,
    //     formTemplate: PACK_FORM_TEMPLATE
    // },
    // {
    //     text: "Ship",
    //     icon: FaTruckRampBox,
    //     info: SHIP_INFO,
    //     formTemplate: SHIP_FORM_TEMPLATE
    // },
    // {
    //     text: "Return",
    //     icon: GiReturnArrow,
    //     info: SALES_RETURN_INFO,
    //     formTemplate: SALES_RETURN_FORM_TEMPLATE
    // },
    // {
    //     text: "Restock",
    //     icon: TbTruckReturn,
    //     info: RESTOCK_INFO,
    //     formTemplate: RESTOCK_FORM_TEMPLATE
    // }
]

const SalesOrderForm = ({
    match,
    setFormInfo,
    setFormTemplate,
    setFormEdit,
    setFormTableData,
    setFormTableLatestRows,
    formTableLatestRows,
    setFormTotalValues,
    setFormTableLatestData,
    formLatestData,
    authToken,
    setFormChangedValues,
    setEditDataForm,
    isNew,
    isRefresh,
    selectedField,
    setSelectedFieldValue,
    setDocumentDropDownItems,
    setFormSideBarNav,
    setFormSideBarNavActiveItem,
    formSideBarNavActiveItem,
    setValueEntredBlur,
    setFromValidationError,
    resetErrorMessageAndIsFail,
    accessRights
}) => {
    const [isSetField, setIsSetField] = useState(false);
    const [isAccessRightChecked, setIsAccessRightCheked] = useState(false);
    const isViewOnly = useRef(false);

    const endpoint = isNew ?
        `${SUB_ITEMS[formSideBarNavActiveItem].info.path}` :
        !formSideBarNavActiveItem ?
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}` :
            `${SUB_ITEMS[formSideBarNavActiveItem].info.path}/${match.params.uuid}${SUB_ITEMS[formSideBarNavActiveItem].info.subPath}`;

    const endpointForQuickComplete = `salesorders/${match.params.uuid}`

    const authTokenFromState = authToken;
    const { data, error } = useFetchEdit(
        endpoint,
        authTokenFromState,
        !isNew,
        true,
        isRefresh,
        formSideBarNavActiveItem
    );

    const fieldEndpoint = `/itemvalues`;
    const { fieldData, fieldError } = useFetchFieldValues(
        fieldEndpoint,
        authTokenFromState,
        selectedField,
        isNew
    )

    const TABLE_DATA = [{
        id: 0
    }];

    const getItemCount = (latestFormRows) => {
        return latestFormRows.length;
    }

    const getSubTotal = (latestFormRows, keys) => {
        const hasNumerLatestFormRows =
            removeObjectWithoutSpecifiedPropertiesHasValue(latestFormRows, keys)

        return (
            formatNumberToTwoDecimal(hasNumerLatestFormRows.reduce((prevResult, row) => {
                return (
                    prevResult + (stringToNumber(row[keys[0]]) * stringToNumber(row[keys[1]]))
                )
            }, 0))
        )
    }

    const getItemTax = (formLatestData, subTotal) => {
        if (!formLatestData.ItemTax || !subTotal) {
            return 0
        }

        const itemTax = formatNumberToTwoDecimal(
            ((stringToNumber(subTotal) * stringToNumber(formLatestData.ItemTax.taxRate)) / 100)
        )
        console.log("about to show item tax in method")
        console.log(formLatestData.ItemTax.value);
        return itemTax;
    }

    const getShippingCost = (formLatestData) => {
        if (!(formLatestData && formLatestData.shippingCost)) {
            return 0;
        }

        return formatNumberToTwoDecimal(stringToNumber(formLatestData.shippingCost))
    }

    const getGrandTotal = (subTotal, itemTax, shippingCost) => {
        if (!subTotal || !itemTax) {
            return 0
        }

        return (formatNumberToTwoDecimal(stringToNumber(subTotal) +
            stringToNumber(itemTax) +
            stringToNumber(shippingCost)));
    }

    const getPaidAmount = () => {
        if (!(formLatestData && formLatestData.paidAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.paidAmount)
    }

    const getAppliedCreditAmount = (formLatestData) => {
        if (!(formLatestData && formLatestData.appliedCreditAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.appliedCreditAmount);
    }

    const getRemainingAmount = (grandTotal, paidAmount) => {
        if (!(grandTotal)) {
            return 0;
        }

        return stringToNumber(grandTotal) - stringToNumber(paidAmount);
    }

    const getRefundAmount = (formLatestData) => {
        if (!(formLatestData && formLatestData.refundAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.refundAmount);
    }

    const getIssuedCreditAmount = (formLatestData) => {
        if (!(formLatestData && formLatestData.issuedCreditAmount)) {
            return 0;
        }

        return stringToNumber(formLatestData.issuedCreditAmount);
    }

    const getBalance = (remainingAmount, refundAmount, issuedCreditAmount) => {
        if (!(remainingAmount)) {
            return 0;
        }

        if (remainingAmount < 0) {
            const afterRefund = roundNumberTwoDecimal(remainingAmount + refundAmount) * -1;
            const afterIssuedCredit = roundNumberTwoDecimal(remainingAmount + issuedCreditAmount) * -1;

            if ((refundAmount &&
                (refundAmount <= (remainingAmount * -1) &&
                    refundAmount <= afterIssuedCredit)) ||
                (issuedCreditAmount <= (remainingAmount * -1) &&
                    issuedCreditAmount <= afterRefund)
            ) {

                return formatNumberToTwoDecimal(
                    (stringToNumber(remainingAmount)) +
                    stringToNumber(refundAmount) +
                    stringToNumber(issuedCreditAmount)
                );
            }
        }

        return formatNumberToTwoDecimal(stringToNumber(remainingAmount));
    }

    const getSubTotalKeys = (formSideBarNavActiveItem) => {
        switch (formSideBarNavActiveItem) {
            case 0:
                return ["qty", "unitPrice"];
            case 4:
                return ["returnQty", "unitPrice"]
        }
    }

    useEffect(() => {
        if (formTableLatestRows.length && (
            formSideBarNavActiveItem === 0 ||
            formSideBarNavActiveItem === 4
        )) {
            const latestFormRows = removeEmptyValuedObjects(formTableLatestRows);
            const itemCount = getItemCount(latestFormRows);
            const subTotalKeys = getSubTotalKeys(formSideBarNavActiveItem)
            const subTotal = getSubTotal(latestFormRows, subTotalKeys);
            const itemTax = getItemTax(formLatestData, subTotal);

            const shippingCost = getShippingCost(formLatestData);
            const grandTotal = getGrandTotal(subTotal, itemTax, shippingCost);
            const paidAmount = getPaidAmount(formLatestData);

            const appliedCreditAmount = getAppliedCreditAmount(formLatestData);
            const totalPaidAmount = (stringToNumber(paidAmount) + stringToNumber(appliedCreditAmount))
            const remainingAmount = getRemainingAmount(grandTotal, totalPaidAmount);
            const refundAmount = getRefundAmount(formLatestData);
            const issuedCreditAmount = getIssuedCreditAmount(formLatestData);
            const balance = getBalance(remainingAmount, refundAmount, issuedCreditAmount);

            const totalValues = {
                itemCount,
                subTotal,
                itemTax,
                shippingCost,
                grandTotal
            };

            const changedValues = {
                payment: [{
                    balance,
                    totalPaidAmount
                }]
            }

            setFormTotalValues(totalValues);
            setFormChangedValues(changedValues);
        }
    }, [formTableLatestRows, formLatestData]);

    useEffect(() => {
        if (selectedField) {
            const selectedFieldValue = {
                name: selectedField.name,
                value: fieldData
            }

            setSelectedFieldValue(selectedFieldValue);
            setIsSetField(true);
        }
    }, [fieldData])


    useEffect(() => {
        if (isSetField) {
            setValueEntredBlur(true);
            setIsSetField(false)
        }
    }, [isSetField])

    useEffect(() => {
        const accessRight = accessRights.find((accessRight => {
            return accessRight.application.toLowerCase() === APP_NAME && APP_NAME.toLowerCase()
        }))

        if (accessRight && (accessRight.permission.toLowerCase() === "view only")) {
            isViewOnly.current = true;
        }
        setIsAccessRightCheked(true)
    }, [])

    useEffect(() => {
        setFormInfo(SUB_ITEMS[formSideBarNavActiveItem].info);

        const formTemplate = clone(SUB_ITEMS[formSideBarNavActiveItem].formTemplate);

        if (isViewOnly.current && Object.keys(formTemplate).length) {
            const bodySections = formTemplate.bodySections;
            disableFormFields(bodySections)
            bodySections.tableForm.hasNoAction = true;
            formSideBarNavActiveItem !== 3 && delete bodySections.tableForm.tableColumns[bodySections.tableForm.tableColumns.length - 1];
        }

        setFormTemplate(formTemplate);
        setDocumentDropDownItems(SALES_ORDER_DOCUMENT_DROP_DOWN_ITEMS);
        setFormTableData(TABLE_DATA);

        if (!isNew) {
            setFormSideBarNav(SUB_ITEMS);
            setFormEdit(true);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFromValidationError(null);
            resetErrorMessageAndIsFail();
        }
    }, [formSideBarNavActiveItem, isViewOnly]);

    useEffect(() => {
        setEditDataForm(data);
    }, [data])

    useEffect(() => {
        return () => {
            setFormInfo(null);
            setFormTemplate(null);
            setDocumentDropDownItems(null);
            setFormTableData(null);
            setFormEdit(false)
            setFormTotalValues(null);
            setFormChangedValues(null);
            setFormTableLatestRows([]);
            setFormTableLatestData({});
            setEditDataForm([]);
            setSelectedFieldValue(null);
            setFormSideBarNav([]);
            setFormSideBarNavActiveItem(0);
        }
    }, [])

    return (
        <div>
            {isAccessRightChecked &&
                Object.keys(SALES_ORDER_FORM_TEMPLATE).length > 0 ?
                <FormContainer
                    endpoint={endpoint}
                    endpointForQuickComplete={endpointForQuickComplete}
                    isViewOnly={isViewOnly.current}
                />
                : null
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    formTableLatestRows: selectFormTableLatestRows,
    formLatestData: selectFormTableLatestData,
    authToken: authTokenSelector,
    isRefresh: selectIsRefresh,
    selectedField: selectSelectedField,
    formSideBarNavActiveItem: selectFormSideBarNavActiveItem,
    accessRights: selectAccessRights
})

const mapDispatchToProps = (disptach) => ({
    setFormInfo: (formInfo) =>
        disptach(setFormInfo(formInfo)),
    setFormTemplate: (formTemplate) =>
        disptach(setFormTemplate(formTemplate)),
    setDocumentDropDownItems: (dropDownItems) =>
        disptach(setDocumentDropDownItems(dropDownItems)),
    setFormType: (modalType) =>
        disptach(setFormType(modalType)),
    setFormTableData: (tableData) =>
        disptach(setFormTableData(tableData)),
    setFormTotalValues: (tableData) =>
        disptach(setFormTotalValues(tableData)),
    setFormChangedValues: (data) =>
        disptach(setFormChangedValues(data)),
    setFormEdit: (data) =>
        disptach(setFormEdit(data)),
    setEditDataForm: (data) =>
        disptach(setEditDataForm(data)),
    setFormTableLatestRows: (data) =>
        disptach(setFormTableLatestRows(data)),
    setFormTableLatestData: (data) =>
        disptach(setFormTableLatestData(data)),
    setSelectedFieldValue: (data) =>
        disptach(setSelectedFieldValue(data)),
    setFormSideBarNav: (data) =>
        disptach(setFormSideBarNav(data)),
    setFormSideBarNavActiveItem: (data) =>
        disptach(setFormSideBarNavActiveItem(data)),
    setFormProgress: (data) =>
        disptach(setFormProgress(data)),
    setFormTemplateEnableField: (data) =>
        disptach(setFormTemplateEnableField(data)),
    setValueEntredBlur: (data) =>
        disptach(setValueEntredBlur(data)),
    setFromValidationError: (errors) =>
        disptach(setFromValidationError(errors)),
    resetErrorMessageAndIsFail: () =>
        disptach(resetErrorMessageAndIsFail()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesOrderForm));