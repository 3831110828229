import "./table-cell-status.styles.scss";

const TableCellStatus = ({ value, noDot }) => {
    return (
        <span className={`table-cell-status ${value.value === 1 ? 
        "table-cell-status--danger": value.value === 2 ||  value.value === 3? 
        "table-cell-status--success"  : ""
    }`}>
             {!noDot && <>&#x2022;</>} {value.label}
        </span>
    )
}

export default TableCellStatus;